<template>
  <div>
    <header-slot />

    <b-nav card-header pills class="m-0">
      <template v-for="(item, index) in filteredTabs">
        <b-nav-item
          v-if="
            ![
              'management-reports-comercial',
            ].includes(item.route) ||
            ([
              'management-reports-comercial',
            ].includes(item.route) &&
              isCeo)
          "
          :key="index"
          :to="{ name: item.route }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          {{ item.name }}
        </b-nav-item>
      </template>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
  data() {
    return {
      tabs: [
        {
          route: "management-reports-monthly-payment",
          name: "MONTHLY PAYMENT",
        },
        {
          route: "management-reports-global-income",
          name: "GLOBAL INCOME",
        },
        {
          route: "management-reports-payment-status",
          name: "PAYMENT STATUS",
        },
        {
          route: "management-reports-vs-payment",
          name: "VS PAYMENT",
        },
        {
          route: "management-reports-general-balance",
          name: "GENERAL BALANCE",
        },
        // {
        //   route: "management-reports-comercial",
        //   name: "COMMERCIAL",
        // },
        {
          route: "management-reports-daily-settlement",
          name: "DAILY SETTLEMENT",
        },
      ],
      filteredTabs: [],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    if (this.moduleId === 5) {
      this.filteredTabs = [
        {
          route: "debtsolution-reports-monthly-payment",
          name: "MONTHLY PAYMENT",
        },
        {
          route: "debtsolution-reports-general-balance",
          name: "GENERAL BALANCE",
        },
      ];
    } else if (this.moduleId === 6) {
      this.filteredTabs = [
        {
          route: "creditexperts-reports-payment-reports",
          name: "PAYMENT REPORT",
        },
        {
          route: "creditexperts-reports-debtor-clients",
          name: "DEBTOR CLIENTS",
        },
      ];
    } else if (
      this.moduleId === 7 ||
      this.moduleId === 10 ||
      this.moduleId === 14
    ) {
      this.filteredTabs = [
        {
          route:
            this.moduleId === 7
              ? "boostcredit-reports-payment-reports"
              : this.moduleId === 10
              ? "claimdepartment-reports-payment-reports"
              : "bookeeping-reports-payment-reports",
          name: "PAYMENT REPORT",
        },
      ];
    } else {
      this.filteredTabs = this.tabs;
    }
  },
  methods: {},
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    // Check if user can navigate directly to the specified route (using user's role and software engineering level)
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
};
</script>

<style>
</style>
